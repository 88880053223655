import axios from 'axios'
import config from '../config'

export async function convertDate(date) {
  let year = date.getFullYear().toString()
  let month = (date.getMonth() + 1).toString()
  let day = date.getDate().toString()
  let dayOfWeek = date.getDay()
  // console.log("test passed:", dayOfWeek, day, month, year)
  if (dayOfWeek !== 0 && dayOfWeek !== 6) {
    if (day.length === 1) day = '0' + day
    if (month.length === 1) month = '0' + month
  }
  return `${year}-${month}-${day}`
}

export async function onPageLoad() {
  return await axios.post(`${config.stocks.filters}/onPageLoad`)
}

export async function getOptions() {
  console.log('get Options for select controls')
  console.log(`${config.stocks.filters}/selectOptions`)
  return await axios.get(`${config.stocks.filters}/selectOptions`)
}

export async function download(name, date) {
  return await axios.get(`${config.stocks.filters}/download/${name}/${date}`)
}

export async function getFilterdetails(name, tdate) {
  let date = await convertDate(tdate)

  console.log(`${config.stocks.filters}/details/${name}/${date}`)
  return await axios.get(`${config.stocks.filters}/details/${name}/${date}`)
}

export async function getFilterByNameDate(name, tdate) {
  let date = await convertDate(tdate)

  console.log(`${config.stocks.filters}/namedate/${name}/${date}`)
  return await axios.get(`${config.stocks.filters}/namedate/${name}/${date}`)
}

export async function getFilterByDate(tdate) {
  let date = await convertDate(tdate)
  console.log('function parameters: ', 'Date: ', date)
  return await axios.get(`${config.stocks.filters}/filters/${date}`)
}

export async function getPerformanceData(date) {
  date = await convertDate(date)
  return await axios.get(`${config.stocks.performance}/${date}`)
}
