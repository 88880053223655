export default function Projectcard() {
  let longs = 15
  return (
    <>
      <div class="card shadow mb-4">
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">Insights</h6>
        </div>
        <div class="card-body">
          <h4 class="small font-weight-bold">
            Short Term Longs <span class="float-right">{longs}</span>
          </h4>
          <div class="progress mb-4">
            <div class="progress-bar bg-danger" role="progressbar"></div>
          </div>
          <h4 class="small font-weight-bold">
            Short Term Shorts <span class="float-right">40%</span>
          </h4>
          <div class="progress mb-4">
            <div class="progress-bar bg-warning" role="progressbar"></div>
          </div>
          <h4 class="small font-weight-bold">
            Excessive Activity <span class="float-right">60%</span>
          </h4>
          <div class="progress mb-4">
            <div class="progress-bar" role="progressbar"></div>
          </div>
          <h4 class="small font-weight-bold">
            Sectors to Watch <span class="float-right">80%</span>
          </h4>
          <div class="progress mb-4">
            <div class="progress-bar bg-info" role="progressbar"></div>
          </div>
          <h4 class="small font-weight-bold">
            Performance <span class="float-right">Complete!</span>
          </h4>
          <div class="progress">
            <div class="progress-bar bg-success" role="progressbar"></div>
          </div>
        </div>
      </div>
    </>
  )
}
