import React, { useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { getTickerData, getTickerOptions } from './service'
//import SSC from '../Chart/SSC'
import Chart from '../Chart/EodMultiSeriesChart'
import Select from 'react-select'

const NWC = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export default function Tickers() {
  const [tickers, setTickers] = useState([])
  const [ticker, setTicker] = useState('')
  const [chartdata, setChartdata] = useState()
  const [name, setName] = useState('')
  const [mktcap, setMktCap] = useState('')
  const [group, setGroup] = useState('')

  // const onChange = (event) => {
  //   console.log(event.target.value)
  //   setTicker(event.target.value)
  // }

  useEffect(() => {
    getTickerOptions()
      .then((symbols) => {
        console.log('returned data: ', symbols)
        setTickers(symbols.data)
        setTicker('IBM')
      })
      .catch()
  }, [])

  useEffect(() => {
    async function fetchData() {
      // You can await here
      await getTickerData(ticker)
        .then(async (response) => {
          // console.log('response.data: ', response.data)
          setChartdata(response.data.chartdata)
          setName(response.data.meta.name)
          setMktCap(NWC(response.data.meta.market_cap))
          setGroup(response.data.meta.sic_description)
        })
        .then(() => {
          //console.log('state: ', chartdata)
        })
    }
    fetchData()
  }, [ticker])

  //console.log("State: ", this.state)
  return (
    <div>
      <Row>
        <Col sm={{ size: 3 }}>
          {/* <Label>Tickers</Label> */}
          <Select
            options={tickers}
            onChange={(ticker) => setTicker(ticker.value)}
            value={ticker}
            placeholder="Ticker"
          />
        </Col>
        <Col sm={{ size: 9 }}>{/* Select Symbol */}</Col>
      </Row>
      <Row>
        <br />
      </Row>
      <Row>
        {/* <Col sm={{ size: 1 }}>{ticker}</Col> */}
        <Col sm={{ size: 4 }}> {name}</Col>
        <Col sm={{ size: 4 }}> {group}</Col>
        <Col sm={{ size: 3 }}> {mktcap}</Col>
      </Row>
      {/* <Label size="lg" >{this.state.ticker}</Label> */}
      <Chart chartdata={chartdata} />
      {/* // key={chartdata.length}
        ticker={ticker}
        data={chartdata}
      /> */}
    </div>
  )
}
