import './App.css'
import Sidebar from './sidebar'
import Topbar from './topbar'
import Footer from './Dashboard/footer'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Dashboard from './Dashboard/card'
import Chart from './Chart/chart'
import Downloads from './Downloads'
import Filters from './Filters'
import Performance from './Performance'
import Processes from './Processes'
import Sectors from './Sectors'
import Tickers from './Tickers'
//import Ranges from './Ranges'

export default function App() {
  return (
    <Router>
      <div id="wrapper">
        <Sidebar></Sidebar>
        <div id="content-wrapper" class="d-flex flex-column">
          <div id="content">
            <Topbar></Topbar>
            <div class="container-fluid">
              <Switch>
                {/* <Route path='/' component={Dashboard}></Route> */}
                <Route path="/dashboard" component={Dashboard}></Route>
                <Route path="/performance" component={Performance}></Route>
                <Route path="/filters" component={Filters}></Route>
                <Route path="/chart" component={Chart}></Route>
                <Route path="/sectors" component={Sectors}></Route>
                <Route path="/downloads" component={Downloads}></Route>
                <Route path="/processes" component={Processes}></Route>
                <Route path="/tickers" component={Tickers}></Route>
              </Switch>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </Router>
  )
}
