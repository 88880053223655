import dashImage from '../Images/undraw_crypto_portfolio_2jy5.svg'
export default function Illustration() {
  return (
    <>
      <div class="card shadow mb-4">
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">
            How to get some coins
          </h6>
        </div>
        <div class="card-body">
          <div class="text-center">
            <img
              class="img-fluid px-3 px-sm-4 mt-3 mb-4"
              src={dashImage}
              alt="..."
            />
          </div>
          <br />
          <p>
            Click on the button below to become a shareholder. And get started
            building your wealth through cryptos
          </p>
        </div>
      </div>
    </>
  )
}
