import React, { Component } from 'react';
import { Label } from 'reactstrap'
import './App.css';
import config from '../config'
import axios from 'axios'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
//import am4themes_animated from "@amcharts/amcharts4/themes/animated";

// // Get data for graphing
async function getDataAsync(ticker) {
    let response = axios({
      method: 'get',
      url: `${config.stocks.eod}/${ticker}`, // gets all the records for a particular stock. 
      config: { headers: {'Content-Type': 'application/json' }}
    })
    //console.log(response)
    return response
}

class SingleSeriesChart extends Component {
	
	constructor(props) {
    super(props);
    this.state= {
      chartdata:"",
      ticker:this.props.ticker
    }
  }

	componentDidMount = (props) => {
    let data=[]
    let self=this
    //console.log(`Prop passed to Single Serice Chart ${this.props.ticker} length : ${this.props.chartdata.length}`)
    getDataAsync(this.props.ticker)
    .then(function (response) {
      for (let i=0;i<response.data.length;i++) { 
        data.push({"date":response.data[i].d, "name":response.data[i].T, "value":response.data[i].c});
      }
      self.setState({chartdata:data})
      console.log("data: ", data.length)
    })
    .then( () => {
      let chart = am4core.create("chartdiv1", am4charts.XYChart);

      chart.paddingRight = 20;

      chart.data = this.state.chartdata  //this.state.series;

      chart.dateFormatter.dateFormat = "yyyy-MM-dd";
      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 0;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.renderer.minWidth = 35;

      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.dateX = "date";
      series.dataFields.valueY = "value";

      series.tooltipText = "{valueY.value}";
      chart.cursor = new am4charts.XYCursor();

      let scrollbarX = new am4charts.XYChartScrollbar();
      scrollbarX.series.push(series);
      chart.scrollbarX = scrollbarX;

      this.chart = chart;
    });
    
  }

  componentDidUpdate(prevProps, prevState ) {
    if (prevProps.ticker !== this.props.ticker) {

      let chart = am4core.create("chartdiv1", am4charts.XYChart);

      chart.paddingRight = 20;

      chart.data = this.props.chartdata  //this.state.series;

      chart.dateFormatter.dateFormat = "yyyy-MM-dd";
      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 0;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.renderer.minWidth = 35;

      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.dateX = "date";
      series.dataFields.valueY = "value";

      series.tooltipText = "{valueY.value}";
      chart.cursor = new am4charts.XYCursor();

      let scrollbarX = new am4charts.XYChartScrollbar();
      scrollbarX.series.push(series);
      chart.scrollbarX = scrollbarX;

      this.chart = chart;
    //});
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return (
      <div>
        <Label size="lg">{this.props.ticker}</Label>
        <div id="chartdiv1" style={{ width: "100%", height: "500px" }}></div>
      </div>
        
    );
  }
}

export default SingleSeriesChart;