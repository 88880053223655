import axios from 'axios'
import config from '../config'
// export async function getTickerdetails(description){
//     return await axios.get(`${config.stocks.eod}/description/${description}`)
// }

async function convertDate(date) {
  let year = date.getFullYear().toString()
  let month = (date.getMonth() + 1).toString()
  let day = date.getDate().toString()
  let dayOfWeek = date.getDay()
  // console.log("test passed:", dayOfWeek, day, month, year)
  if (dayOfWeek !== 0 && dayOfWeek !== 6) {
    if (day.length === 1) day = '0' + day
    if (month.length === 1) month = '0' + month

    return `${year}-${month}-${day}`
  } else {
    // console.log("Test Failed:", dayOfWeek)
    return false
  }
}

export async function getDates() {
  return await axios.get(`${config.system.processes}/dates`)
}

export async function runProcess(name, date) {
  //console.log("passed in Date: ", date)
  let newDate = await convertDate(date)
  if (!newDate) return 'Invalid Date'
  // console.log("fetch url: ", `${config.system.processes}/${name}/${newDate}` )
  return await axios.get(`${config.system.processes}/${name}/${newDate}`)
}
