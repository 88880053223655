import axios from 'axios'
import config from '../config'

export async function getFile(file) {
  return await axios.get(`${config.stocks.downloads}/download/${file}`)
}

// get a list of the files
export async function getFiles(date) {
  return await axios.get(`${config.stocks.downloads}/files/${date}`)
}
