import axios from 'axios'
import config from '../config'
// export async function getTickerdetails(description){
//     return await axios.get(`${config.stocks.eod}/description/${description}`)
// }

export async function getSectorTickers(description) {
  return await axios.get(`${config.stocks.sectors}/${description}`)
}

export async function getOptions() {
  return await axios.get(`${config.stocks.sectors}/sectors`)
}

export async function getSectors() {
  return await axios.get(`${config.stocks.sectors}/sectors`)
}

export async function createWatchlist() {
  return await axios.get(`${config.stocks.sectors}/watchlist`)
}
