import axios from 'axios'
import config from '../config'

export async function getTickerData(ticker) {
  return await axios.get(`${config.stocks.tickers}/${ticker}`)
}

export async function getTickerOptions(date = 0) {
  console.log(
    'get Ticker options',
    `${config.stocks.tickers}/selectOptions/${date}`,
  )
  return await axios.get(`${config.stocks.tickers}/selectOptions/${date}`)
}
