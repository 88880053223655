//import React, {useEffect, useState} from 'react'
import { Table } from 'antd'
import { Row } from 'reactstrap'

//
// will get an array of objects (data) passed in. extract the properties for the thead and data for the body. Each row should be sortable.

export default function DisplayTable(props) {
  const data = props.data
  const columnObj = data[0]

  const columns = []
  for (const property in columnObj) {
    if (typeof columnObj[property] !== 'object') {
      columns.push({
        dataIndex: property,
        title: capitalize(property),
        sorter: (a, b) => {
          const diff = a[property] - b[property]
          return isNaN(diff)
            ? a[property].localeCompare(b[property])
            : diff
            ? diff / Math.abs(diff)
            : 0
        },
      })
    }
  }

  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }

  return (
    <div>
      <h1>{props.sector}</h1>
      <div class="card-body">
        <div>
          <Row>
            {/* <Col sm={{ size: 3 }}>Group Gains:{props.gains.toFixed(2)}</Col>
            <Col sm={{ size: 3 }}>Group losses:{props.losses.toFixed(2)}</Col> */}
          </Row>
        </div>
        <div class="table-responsive">
          <Table columns={columns} dataSource={data} />
        </div>
      </div>
    </div>
  )
}
