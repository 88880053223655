import React from 'react'
import { Table } from 'antd'

//
// will get an array of objects (data) passed in. extract the properties for the thead and data for the body. Each row should be sortable.

// function numberWithCommas(x) {
//   return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
// }

export default function DisplayTable(props) {
  const data = props.data
  const columnObj = data[0]

  const columns = []
  for (const property in columnObj) {
    if (typeof columnObj[property] !== 'object') {
      columns.push({
        dataIndex: property,
        title: capitalize(property),
        sorter: (a, b) => {
          const diff = a[property] - b[property]
          if (a[property] !== undefined)
            return isNaN(diff)
              ? a[property].localeCompare(b[property])
              : diff
              ? diff / Math.abs(diff)
              : 0
        },
      })
    }
  }

  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }

  return (
    <div>
      <div class="card-body">
        <div class="table-responsive">
          <Table columns={columns} dataSource={data} />
        </div>
      </div>
    </div>
  )
}
