import React, { useState, useEffect } from 'react'
import { getOptions, getFilterByNameDate } from './service'
//import DisplayTable from './DisplayTable'
import Table from './Table'

import Datepicker from 'react-datepicker'
import Select from 'react-select'
import { Row, Col, Label, Button } from 'reactstrap'
import 'react-datepicker/dist/react-datepicker.css'

export default function Index() {
  // const [loading, setLoading] = useState(true)
  // const [button, setButton] = useState()
  const [date, setDate] = useState('')
  const [total, setTotal] = useState(0)
  const [tabledata, setTabledata] = useState([])
  const [bears, setBears] = useState([])
  const [bulls, setBulls] = useState([])
  const [neutral, setNeutral] = useState([])
  const [names, setNames] = useState([])
  const [filter, setFilter] = useState('')
  const [headers, setHeaders] = useState([])

  const isWeekday = (tdate) => {
    //console.log('tdate: ', tdate)
    const day = tdate.getDay()
    //console.log(' isWeekDay: ', day !== 0 && day !== 6)
    return day !== 0 && day !== 6
  }

  useEffect(() => {
    getOptions()
      .then((data) => {
        console.log('names : ', data.data)
        setNames(data.data)
        setFilter('All')
        //console.log('date: ', data.data.date)
        //setDate(data.data.date)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  // if (names.length === 0) {
  //   return null
  // }

  // const handleFilterChange = (item) => {
  //   console.log('Item: ', item)
  //   setFilter(item.value)
  //   console.log('filter: ', filter)
  // }

  const handleSubmit = () => {
    console.log('getFilter params: ', filter, date)
    getFilterByNameDate(filter.value, date)
      .then((res) => {
        if (res.data.tabledata === []) {
          setTabledata({ name: ['No data available'] })
        } else {
          setHeaders(Object.keys(res.data.tabledata[0]))
          setTabledata(res.data.tabledata)
          setBulls(res.data.bulls)
          setBears(res.data.bears)
          setTotal(res.data.count)
          setNeutral(res.data.neutral)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  // if (tabledata.length === 0) {
  //   return null
  // }

  return (
    <div>
      <Row>
        <Col sm={{ size: 3 }}>
          <Datepicker
            selected={date}
            onChange={(date) => setDate(date)}
            filterDate={isWeekday}
            placeholderText="Select a weekday"
          />
        </Col>
        <Col sm={{ size: 3 }}>
          <Select
            options={names}
            onChange={(filter) => setFilter(filter)}
            value={filter}
            placeholder="Filters"
          />
        </Col>
        <Col sm={{ size: 3 }}>
          <Button color="success" onClick={handleSubmit}>
            Send
          </Button>{' '}
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 3 }}> Total: {total}</Col>
        <Col sm={{ size: 3 }}> Neutral: {neutral}</Col>
        <Col sm={{ size: 3 }}> Bulls: {bulls}</Col>
        <Col sm={{ size: 3 }}> Bears: {bears}</Col>
      </Row>
      <Row>
        <Col sm={{ size: 3 }}>
          <Label></Label>
        </Col>
      </Row>
      {/* <DisplayTable key={tabledata.length} data={tabledata} headers={headers} /> */}
      {headers.length > 0 && <Table tabledata={tabledata} headers={headers} />}
    </div>
  )
}
