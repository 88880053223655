import React from 'react'
import './table.css'

// const TableHeader = () => {
//   return (
//     <thead>
//       <tr>
//         <th>Name</th>
//       </tr>
//     </thead>
//   )
// }

const TableHeader = (props) => {
  const headerRow = props.headers.map((heading) => {
    return <th>{heading}</th>
  })

  return (
    <thead>
      <tr>{headerRow}</tr>
    </thead>
  )
}

const TableBody = (props) => {
  const rowData = props.tabledata.map((item, index) => {
    return (
      <tr
        key={index}
        style={{ cursor: 'pointer', color: 'blue' }}
        // onClick={() => props.rowHandler}
      >
        {Object.values(item).map((value) => {
          return <td>{value}</td>
        })}
      </tr>
    )
  })

  //let style = {}

  return <tbody>{rowData}</tbody>
}

const Table = (props) => {
  //console.log("Props: ", props)
  return (
    <div>
      <p style={{ fontSize: '24px', fontWeight: 'bold' }}>{props.sector}</p>
      <table className="ReactTable">
        <TableHeader headers={props.headers} />
        <TableBody tabledata={props.tabledata} />
      </table>
    </div>
  )
}

export default Table

// import React from 'react';
// import {Button} from 'reactstrap'
// import './table.css'

// const TableHeader = () => {
//   return (
//     <thead>
//       <tr>
//         <th>Ticker</th>
//         <th>Open</th>
//         <th>High</th>
//         <th>Low</th>
//         <th>Close</th>
//         <th>Range</th>
//         <th>Volume</th>
//         <th>Weighted</th>
//         <th>Cnt</th>
//         <th>Date</th>
//       </tr>
//     </thead>
//   );
// }

// const TableBody = props => {
//   let style = {}
//   const rows = props.tabledata.map((row, index) => {
//     return (
//       <tr key={index} style={style}>
//         <td>{row.T}</td>
//         <td>{row.o}</td>
//         <td>{row.h}</td>
//         <td>{row.l}</td>
//         <td>{row.c}</td>
//         <td>{(row.h-row.l).toFixed(2)}</td>
//         <td>{row.v}</td>
//         <td>{row.vw}</td>
//         <td>{row.n}</td>
//         <td>{row.d}</td>
//         {/* <td>
//           <Button size="sm" onClick={() => props.handleSubmit(row, index)}>use</Button>
//         </td> */}
//       </tr>
//     );
//   });
//   return <tbody>{rows}</tbody>;
// }

// const Table = (props) => {
//   console.log("Props: ", props)
//   return (
//     <div>
//       <p style={{fontSize:'24px', fontWeight:'bold'}}>{props.filter}</p>
//       <Button color="primary">download</Button>{' '}
//       <table className="ReactTable">
//         <TableHeader />
//         <TableBody
//           tabledata={props.tabledata}
//         />
//       </table>
//     </div>
//   );
// }

// export default Table;
