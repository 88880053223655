import axios from 'axios'
import config from '../config'

export async function convertDate(date) {
  let year = date.getFullYear().toString()
  let month = (date.getMonth() + 1).toString()
  let day = date.getDate().toString()
  let dayOfWeek = date.getDay()
  // console.log("test passed:", dayOfWeek, day, month, year)
  if (dayOfWeek !== 0 && dayOfWeek !== 6) {
    if (day.length === 1) day = '0' + day
    if (month.length === 1) month = '0' + month
  }
  return `${year}-${month}-${day}`
}

export async function getPerformanceData(date) {
  date = await convertDate(date)
  return await axios.get(`${config.stocks.performance}/${date}`)
}

export async function getMulitples(date) {
  date = await convertDate(date)
  return await axios.get(`${config.stocks.performance}/multiples/${date}`)
}
