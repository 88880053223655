export default function Footer() {
  let year = new Date().getFullYear()
  return (
    <>
      <footer class="sticky-footer bg-white">
        <div class="container my-auto">
          <div class="copyright text-center my-auto">
            <span>Copyright &copy; Marx Analytics {year}</span>
          </div>
        </div>
      </footer>
    </>
  )
}
