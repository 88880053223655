import React, { useState } from 'react'
import { runProcess } from './service'
import { Row, Col, Button } from 'reactstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

function Process() {
  const [startDate, setStartDate] = useState(new Date())
  const [running, setRunning] = useState('')

  const isWeekday = (date) => {
    const day = date.getDay()
    return day !== 0 && day !== 6
  }

  const runProcessEod = () => {
    setRunning('running')
    console.log('run process eod for: ', startDate)
    runProcess('eod', startDate).then((res) => {
      console.log(res)
      setRunning('completed')
    })
  }

  const runProcessCreateLists = () => {
    setRunning('running')
    runProcess('createLists', startDate).then((res) => {
      console.log(res.status)
      setRunning('completed')
    })
  }

  const runProcessWriteWatchlists = (name) => {
    setRunning('running')
    runProcess('writeWatchlists', startDate).then((res) => {
      console.log(res)
      setRunning('completed')
    })
  }
  return (
    <div>
      <Row>
        <Col sm={{ size: 3 }}>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            filterDate={isWeekday}
            placeholderText="Select a weekday"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button onClick={runProcessEod}>Run EOD</Button>
        </Col>
        <Col>
          <Button onClick={runProcessCreateLists}>Create Lists</Button>
        </Col>
        <Col>
          <Button onClick={runProcessWriteWatchlists}>Write Lists</Button>
        </Col>
      </Row>
      <Row>
        <Col>Status: {running}</Col>
      </Row>
    </div>
  )
}
export default Process
