import React, { Component } from 'react'
import config from '../config'
//import Table from './Table'
//import React, {useEffect, useState, Component} from 'react'
import Table from './Table'
import { getSectorTickers } from './service'
// import { AvForm, AvGroup} from 'availity-reactstrap-validation';
import { Row, Col } from 'reactstrap'
import Select from 'react-select'
//import { Label } from 'reactstrap'

export default class Sectors extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      isLoading: true,
      sectors: [],
      selectedOption: '',
      sector: '',
      tabledata: [],
      currentDate: '',
      gains: 0,
      losses: 0,
      rsi: 0,
      iRsi: 0,
    }
    this.rowHandler = this.rowHandler.bind(this)
    this.state = this.initialState
  }

  async componentDidMount() {
    // if (!this.props.isAuthenticated) {
    //   return;
    // }
    this.setState({ isLoading: false })
    let url = `${config.stocks.sectors}/options`
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ sectors: data })
      })
      .catch((err) => {
        console.log(err)
        this.setState(this.initialState)
      })
  }

  rowHandler = (dataIndex) => {
    console.log('onClick rowHandler: ', dataIndex)
  }

  handleChange = (selectedOption) => {
    let self = this
    getSectorTickers(selectedOption.value)
      .then(function (response) {
        self.setState(
          {
            tabledata: response.data.result,
            sector: selectedOption.value,
            gains: response.data.gains,
            losses: response.data.losses,
          },
          (err) => {
            if (err) {
              console.log('Error: ', err)
            } else {
              console.log('response: ', response.data.result)
              console.log('metadata: ', response.data.metadata)
            }
          },
        )
      })
      .catch((err) => {
        console.log(err)
      })
  }

  renderForm() {
    return (
      <div>
        <Row>
          <Col sm={{ size: 9 }}>
            {/* <Label>Sectors</Label> */}
            <Select
              options={this.state.sectors}
              onChange={this.handleChange}
              value={this.state.sector}
              placeholder="Sectors"
            />
          </Col>
          <Col sm={{ size: 3 }}>{/* Select Symbol */}</Col>
        </Row>
      </div>
    )
  }

  render() {
    //console.log("State: ", this.state)
    return (
      <div className="Home">
        {this.renderForm()}
        <Table
          rowHandler={this.state.rowHandler}
          data={this.state.tabledata}
          sector={this.state.sector}
          losses={this.state.losses}
          gains={this.state.gains}
        />
      </div>
    )
  }

  // render() {
  // 	//console.log("State: ", this.state)
  //   return (
  //     <div className="Home">
  // 			{this.renderForm()}
  // 			<Table
  // 				transactions={this.state.tabledata}
  // 				sector={this.state.sector}
  // 			/>
  //     </div>
  //   );
  // }

  // render() {
  // 	console.log("State: ", this.state)
  //   return (
  //     <div className="Home">
  //       {this.state.sector ? (
  //         this.renderPanel()
  //         ) : (
  //         this.renderPanel())}
  //     </div>
  //   );
  // }
}

// import data from '../Data/A-eods.json'
// import {getEOD} from './service'

//
// will get an array of objects (data) passed in. extract the properties for the thead and data for the body. Each row should be sortable.

// export default function Index() {

//     const [state, setState] = useState({
//         data: []
//     });

//     useEffect(() => {
//         async function fetchData() {
//             const {data} = await getEOD();
//             setState({...state, data});
//         }

//         fetchData();
//     }, [])

//     const {data} = state;
//     const columnObj = data[0];

//     const columns = [];
//     for (const property in columnObj) {
//         if (typeof columnObj[property] !== "object") {
//             columns.push({
//                 dataIndex: property,
//                 title: capitalize(property),
//                 sorter: (a, b) => {
//                     const diff = a[property] - b[property]
//                     return isNaN(diff) ? a[property].localeCompare(b[property]) : (diff ? diff / Math.abs(diff) : 0);
//                 }
//             });
//         }
//     }

//     function capitalize(string) {
//         return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
//     }

//     return (
//         <div>
//             <h1>End of Day</h1>
//             <div class="card-body">
//                 <div class="table-responsive">
//                     <Table columns={columns} dataSource={data}/>
//                 </div>
//             </div>
//         </div>
//     )
// }
