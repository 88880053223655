import axios from 'axios'
import React, { Component } from 'react'
import Select from 'react-select'
// import { AvForm, AvGroup} from 'availity-reactstrap-validation';
import { Col, Row } from 'reactstrap'
import config from '../config'
import SingleSeriesChart from './SingleSeriesChart'

// Get data for graphing
async function getDataAsync(ticker) {
  let response = await axios({
    method: 'get',
    url: `${config.stocks.eod}/${ticker}`, // gets all the records for a particular stock.
    config: { headers: { 'Content-Type': 'application/json' } },
  })
  //console.log(response)
  return response
}

export default class Chart extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      isLoading: true,
      tickers: [],
      selectedOption: '',
      ticker: 'IBM',
      chartdata: [],
    }
    //this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    this.state = this.initialState
  }

  async componentDidMount() {
    // if (!this.props.isAuthenticated) {
    //   return;
    // }

    this.setState({ isLoading: false })
    // let self=this
    // let data=[]
    // getDataAsync(this.state.ticker)
    // .then(async (response) => {
    //   for (let i=0;i<response.data.length;i++) {
    //     data.push({"date":response.data[i].d, "name":response.data[i].T, "value":response.data[i].c});
    //   }
    //   self.setState({chartdata:data}, (err) =>{
    // 		if (err) {
    // 			console.log("Error: ", err)
    // 		}
    // 	})
    // })
    // .catch((err)=>{console.log(err)})

    // let url = `${config.stocks.eod}/IBM`
    // fetch(url)
    // .then(response => response.json())
    // .then(data => {
    //   this.setState({tickers:data })
    // })
    // .catch(err => {
    //   console.log(err)
    //   this.setState(this.initialState)
    // });
  }

  handleChange = (selectedOption) => {
    // setState({ticker:selectedO})
    //this.setState({ticker:selectedOption.value})
    let data = []
    let self = this
    //let symbol = selectedOption.value
    getDataAsync(this.state.ticker)
      .then(function (response) {
        for (let i = 0; i < response.data.length; i++) {
          data.push({
            date: response.data[i].d,
            name: response.data[i].T,
            value: response.data[i].c,
          })
        }
        self.setState(
          { chartdata: data, ticker: selectedOption.value },
          (err) => {
            if (err) {
              console.log('Error: ', err)
            }
          },
        )
      })
      .catch((err) => {
        console.log(err)
      })
  }

  renderChart() {
    return (
      <div>
        <Row>
          <Col sm={{ size: 3 }}>
            {/* <Label>Tickers</Label> */}
            <Select
              options={this.state.tickers}
              onChange={this.handleChange}
              value={this.state.ticker}
              placeholder="Tickers"
              defaultValue={this.state.tickers[0]}
            />
          </Col>
          <Col sm={{ size: 9 }}>{/* Select Symbol */}</Col>
        </Row>
        {/* <Label size="lg" >{this.state.ticker}</Label> */}
        <SingleSeriesChart
          key={this.state.chartdata.length}
          ticker={this.state.ticker}
        />
      </div>
    )
  }

  renderForm() {
    return (
      <div>
        <Row>
          <Col sm={{ size: 3 }}>
            {/* <Label>Tickers</Label> */}
            <Select
              options={this.state.tickers}
              onChange={this.handleChange}
              value={this.state.ticker}
              placeholder="Tickers"
            />
          </Col>
          <Col sm={{ size: 9 }}>{/* Select Symbol */}</Col>
        </Row>
      </div>
    )
  }

  render() {
    //console.log("State: ", this.state)
    return (
      <div className="Home">
        {this.state.ticker ? this.renderChart() : this.renderForm()}
      </div>
    )
  }
}
