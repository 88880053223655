import React from 'react'
import './table.css'

// const TableHeader = () => {
//   return (
//     <thead>
//       <tr>
//         <th>Name</th>
//       </tr>
//     </thead>
//   )
// }

const TableHeader = (props) => {
  let headings = ['name']
  const row = headings.map((heading, index) => {
    return <th>{heading}</th>
  })
  return (
    <thead>
      <tr>{row}</tr>
    </thead>
  )
}

const TableBody = (props) => {
  let style = {}
  const rows = props.transactions.map((row, index) => {
    return (
      <tr key={index} style={style}>
        <td
          style={{ cursor: 'pointer', color: 'blue' }}
          onClick={() => props.downloadFile(row, index)}
        >
          {row.name}
        </td>

        {/* <td>
          <Button size="sm" onClick={() => props.handleSubmit(row, index)}>use</Button>  
        </td> */}
      </tr>
    )
  })
  return <tbody>{rows}</tbody>
}

const Table = (props) => {
  //console.log("Props: ", props)
  return (
    <div>
      <p style={{ fontSize: '24px', fontWeight: 'bold' }}>{props.sector}</p>
      <table className="ReactTable">
        <TableHeader />
        <TableBody
          transactions={props.transactions}
          downloadFile={props.downloadFile}
        />
      </table>
    </div>
  )
}

export default Table
