const config = {
  apiServer: 'https://blade.marxanalytics.com',
  MtServer: {
    host: '185.95.17.202',
    username: 'cranni',
    password: '1qazXSW@',
    db: 'metatrader',
  },

  cognito: {
    REGION: 'us-east-1',
    UserPoolId: 'us-east-1_UNLMMFwAr',
    APP_CLIENT_ID: '4rqd241vkb3tu8cpkqvgevrdr8',
    IDENTITY_POOL_ID: 'us-east-1:426ea851-e4f6-4b78-8288-400af48ea854',
  },
  cognitoAdmin: {
    REGION: 'us-east-1',
    UserPoolId: 'us-east-1_UNLMMFwAr',
    APP_CLIENT_ID: '4rqd241vkb3tu8cpkqvgevrdr8',
    IDENTITY_POOL_ID: 'us-east-1:426ea851-e4f6-4b78-8288-400af48ea854',
  },

  api: {
    server: 'https://blade.marxanalytics.com',
    //server:"https://52.21.235.215:3010"
    //server:"https://blade.marxanalytics.com:3101"
  },
  stocks: {
    eod: `https://blade.marxanalytics.com/eod`,
    tickers: `https://blade.marxanalytics.com/tickers`,
    frequency: `https://blade.marxanalytics.com/frequency`,
    filters: `https://blade.marxanalytics.com/filters`,
    options: `https://blade.marxanalytics.com/options`,
    averages: `https://blade.marxanalytics.com/averages`,
    sectors: `https://blade.marxanalytics.com/sectors`,
    processes: `https://blade.marxanalytics.com/processes`,
    performance: `https://blade.marxanalytics.com/performance`,
    downloads: `https://blade.marxanalytics.com/downloads`,
  },
  system: {
    processes: `https://blade.marxanalytics.com/processes`,
  },
}
module.exports = config

// stocks: {
//   eod: "https://marxgroupapi.ngrok.io/eod",
//     tickers: "https://marxgroupapi.ngrok.io/tickers",
//       frequency: "https://marxgroupapi.ngrok.io/frequency",
//         filters: "https://marxgroupapi.ngrok.io/filters",
//           options: "https://marxgroupapi.ngrok.io/options",
//             averages: "https://marxgroupapi.ngrok.io/averages"
// }
