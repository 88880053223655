import React, { useState } from 'react'
import { getMulitples } from './service'
import DisplayTable from './DisplayTable'
import Datepicker from 'react-datepicker'
import { Row, Col, Button } from 'reactstrap'
import 'react-datepicker/dist/react-datepicker.css'

export default function Performance() {
  const [date, setDate] = useState()
  const [tabledata, setTabledata] = useState([])
  const [bears, setBears] = useState(0)
  const [bulls, setBulls] = useState(0)

  const [count, setCount] = useState(0)

  const isWeekday = (date) => {
    const day = date.getDay()
    return day !== 0 && day !== 6
  }

  const handleSelected = (date) => {
    console.log('handle selected :', date)
    setDate(date)
    getMulitples(date)
      .then((res) => {
        //console.log('returned: ', res.data)
        setTabledata(res.data.records)
        setBulls(res.data.bulls)
        setBears(res.data.bears)
        setCount(res.data.count)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div>
      <Row>
        <Col sm={{ size: 3 }}>
          <Datepicker
            selected={date}
            onChange={(date) => setDate(date)}
            filterDate={isWeekday}
            placeholderText="Select a weekday"
            onSelect={handleSelected}
          />
        </Col>
        <Col sm={{ size: 3 }}>
          <Button>Create WL</Button>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 2 }}>Total:{count}</Col>
        <Col sm={{ size: 2 }}>Bulls:{bulls}</Col>
        <Col sm={{ size: 2 }}>Bears:{bears}</Col>
      </Row>
      <DisplayTable data={tabledata} />
    </div>
  )
}
