import React, { Component } from 'react'
import config from '../config'
import Table from './Table'
import { getFile } from './service'
import { Row, Col } from 'reactstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import FileDownload from 'js-file-download'

async function convertDate(date) {
  let year = date.getFullYear().toString()
  let month = (date.getMonth() + 1).toString()
  let day = date.getDate().toString()
  let dayOfWeek = date.getDay()
  // console.log("test passed:", dayOfWeek, day, month, year)
  if (dayOfWeek !== 0 && dayOfWeek !== 6) {
    if (day.length === 1) day = '0' + day
    if (month.length === 1) month = '0' + month
  }
  return `${year}-${month}-${day}`
}

export default class Downloads extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      isLoading: true,
      files: [],
      selectedOption: '',
      file: '',
      tabledata: [],
      startDate: '',
      date: new Date(),
      convertedDate: '',
      headings: '',
    }
    this.downloadFile = this.downloadFile.bind(this)
    this.state = this.initialState
  }

  async componentDidMount() {
    // if (!this.props.isAuthenticated) {
    //   return;
    // }
    console.log('intial state: ', this.state)
    this.setState({ isLoading: false })
  }

  isWeekday = (date) => {
    const day = date.getDay()
    return day !== 0 && day !== 6
  }

  async downloadFile(row, index) {
    console.log(`Row : ${row.name}`)
    getFile(`${row.name}`).then((response) => {
      FileDownload(response.data, `${row.name}`)
    })
  }

  handleDateSelect = (date) => {
    console.log('Selected Date: ', date)
    convertDate(date).then((newDate) => {
      let url = `${config.stocks.downloads}/files/${newDate}`
      console.log('Url:  ', url)
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          console.log('return data: ', data)
          let titles = Object.keys(data)
          console.log('Titles: ', titles)
          this.setState({ files: data, headings: titles })
        })
        .catch((err) => {
          console.log(err)
          this.setState(this.initialState)
        })
    })
  }

  render() {
    //console.log("State: ", this.state)
    return (
      <div className="Home">
        <Row>
          <Col sm={{ size: 9 }}>
            {/* <Label>Sectors</Label> */}
            <DatePicker
              selected={this.state.startDate}
              onChange={(date) => this.setState({ startDate: date })}
              filterDate={this.isWeekday}
              placeholderText="Select a weekday"
              dateFormat="yyyy-MM-dd"
              onSelect={this.handleDateSelect}
            />
          </Col>
        </Row>
        <Table
          transactions={this.state.files}
          downloadFile={this.downloadFile}
          headings={this.state.headings}
        />
      </div>
    )
  }
}
