import { Link } from 'react-router-dom'

export default function Sidebar() {
  return (
    <>
      <ul
        class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        id="accordionSidebar"
      >
        <a
          class="sidebar-brand d-flex align-items-center justify-content-center"
          href="index.html"
        >
          <div class="sidebar-brand-icon rotate-n-15">
            <i class="fab fa-battle-net"></i>
          </div>
          <div class="sidebar-brand-text mx-3">Marx Analyitics</div>
        </a>
        <hr class="sidebar-divider my-0" />
        <li class="nav-item active">
          <Link to="/Dashboard" class="nav-link" href="index.html">
            <i class="fas fa-fw fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </Link>
        </li>
        <li class="nav-item active">
          <Link to="/Processes" class="nav-link" href="index.html">
            <i class="fas fa-fw fa-tachometer-alt"></i>
            <span>Processes</span>
          </Link>
        </li>
        <hr class="sidebar-divider" />

        <div class="sidebar-heading">Interface</div>

        <li class="nav-item">
          <Link
            to="/filters"
            class="nav-link collapsed"
            href="index.html"
            data-toggle="collapse"
            data-target="#collapseUtilities"
            aria-expanded="true"
            aria-controls="collapseUtilities"
          >
            <i class="fas fa-fw fa-wrench"></i>
            <span>Filters</span>
          </Link>
        </li>
        <li class="nav-item">
          <Link
            to="/performance"
            class="nav-link collapsed"
            href="index.html"
            data-toggle="collapse"
            data-target="#collapseUtilities"
            aria-expanded="true"
            aria-controls="collapseUtilities"
          >
            <i class="fas fa-fw fa-wrench"></i>
            <span>Performance</span>
          </Link>
        </li>

        <li class="nav-item">
          <Link
            to="/Chart/chart"
            class="nav-link collapsed"
            href="index.html"
            data-toggle="collapse"
            data-target="#collapseUtilities"
            aria-expanded="true"
            aria-controls="collapseUtilities"
          >
            <i class="fas fa-fw fa-wrench"></i>
            <span>Chart</span>
          </Link>
        </li>

        <li class="nav-item">
          <Link
            to="./tickers"
            class="nav-link collapsed"
            href="index.html"
            data-toggle="collapse"
            data-target="#collapseUtilities"
            aria-expanded="true"
            aria-controls="collapseUtilities"
          >
            <i class="fas fa-fw fa-wrench"></i>
            <span>Ticker Details</span>
          </Link>
        </li>

        <li class="nav-item">
          <Link
            to="./sectors"
            class="nav-link collapsed"
            href="index.html"
            data-toggle="collapse"
            data-target="#collapseUtilities"
            aria-expanded="true"
            aria-controls="collapseUtilities"
          >
            <i class="fas fa-fw fa-wrench"></i>
            <span>Sectors</span>
          </Link>
        </li>

        {/* <li class="nav-item">
          <Link
            to="./createFilter"
            class="nav-link collapsed"
            href="index.html"
            data-toggle="collapse"
            data-target="#collapseUtilities"
            aria-expanded="true"
            aria-controls="collapseUtilities"
          >
            <i class="fas fa-fw fa-wrench"></i>
            <span>Create Filter</span>
          </Link>
        </li> */}

        <li class="nav-item">
          <Link
            to="./downloads"
            class="nav-link collapsed"
            href="index.html"
            data-toggle="collapse"
            data-target="#collapseUtilities"
            aria-expanded="true"
            aria-controls="collapseUtilities"
          >
            <i class="fas fa-fw fa-wrench"></i>
            <span>Downloads</span>
          </Link>
        </li>

        {/*<li class="nav-item">
            <Link to="./range" class="nav-link collapsed" href="index.html"  data-toggle="collapse" data-target="#collapseUtilities"
                  aria-expanded="true" aria-controls="collapseUtilities">
                  <i class="fas fa-fw fa-wrench"></i>
                  <span>Ranges</span>
            </Link>
         </li> */}
        {/* <hr class="sidebar-divider" />

         <div class="text-center d-none d-md-inline">
            <a class="rounded-circle border-0" href="index1.html" id="sidebarToggle">  </a>
         </div> */}
        {/* <div class="sidebar-card d-none d-lg-flex">
               <img class="sidebar-card-illustration mb-2" src="img/undraw_rocket.svg" alt="..." />
               <p class="text-center mb-2"><strong>SB Admin Pro</strong> is packed with premium features, components, and more!</p>
               <a class="btn btn-success btn-sm" href="https://startbootstrap.com/theme/sb-admin-pro">Upgrade to Pro!</a>
         </div> */}
      </ul>
    </>
  )
}
